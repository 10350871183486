import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { InitWrapper } from '../pages/init/InitWrapper'
import { useAuth } from '../modules/auth'
import { Menu, Permission } from '../models'

const PrivateRoutes = () => {
  const { userCan } = useAuth()

  const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'))
  const ReportPage = lazy(() => import('../pages/report/ReportPage'))
  const WorkingUnitWrapper = lazy(() => import('../pages/working-unit/WorkingUnitWrapper'))
  const AccessControlWrapper = lazy(() => import('../pages/access-control/AccessControlWrapper'))
  const UserWrapper = lazy(() => import('../pages/user/UserWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect after success login */}
        <Route path="auth/*" element={<Navigate to="/init" />} />
        {/* Pages */}
        <Route path="init" element={<InitWrapper />} />
        <Route
          path="dashboard"
          element={
            userCan(Permission.READ, Menu.DASHBOARD) ? (
              <SuspensedView>
                <DashboardWrapper />
              </SuspensedView>
            ) : (
              <Navigate to="/error/404" />
            )
          }
        />
        <Route
          path="reports/*"
          element={
            userCan(Permission.READ, Menu.REPORT) ? (
              <SuspensedView>
                <ReportPage />
              </SuspensedView>
            ) : (
              <Navigate to="/error/404" />
            )
          }
        />
        <Route
          path="working-units"
          element={
            userCan(Permission.READ, Menu.WORKING_UNIT) ? (
              <SuspensedView>
                <WorkingUnitWrapper />
              </SuspensedView>
            ) : (
              <Navigate to="/error/404" />
            )
          }
        />
        <Route
          path="access-controls"
          element={
            userCan(Permission.READ, Menu.ACCESS_CONTROL) ? (
              <SuspensedView>
                <AccessControlWrapper />
              </SuspensedView>
            ) : (
              <Navigate to="/error/404" />
            )
          }
        />
        <Route
          path="users"
          element={
            userCan(Permission.READ, Menu.USER) ? (
              <SuspensedView>
                <UserWrapper />
              </SuspensedView>
            ) : (
              <Navigate to="/error/404" />
            )
          }
        />
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
