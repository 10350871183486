import { FC, useEffect } from 'react'
import { useAuth } from '../../modules/auth'
import { firstMenu } from '../../utils/firstMenu'
import { useNavigate } from 'react-router-dom'

const InitWrapper: FC = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const menu = firstMenu(currentUser?.menus ?? [])

  useEffect(() => {
    if (menu) {
      navigate(menu.url, { replace: true })
    }
  }, [menu, navigate])

  return <>Redirecting...</>
}

export { InitWrapper }
