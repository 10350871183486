import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import messages from './i18n/id/messages.json'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <IntlProvider locale="id" messages={messages}>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </IntlProvider>
    </Suspense>
  )
}

export { App }
