import * as Yup from 'yup'
import * as id from '../../../i18n/id/validation'
import { intl } from '../../../utils/i18n'

Yup.setLocale(id)

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
    .label(intl.formatMessage({ id: 'Email' })),
  password: Yup.string()
    .required()
    .label(intl.formatMessage({ id: 'Password' })),
})

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
    .label(intl.formatMessage({ id: 'Email' })),
})

export const resetPasswordSchema = Yup.object().shape({
  token: Yup.string()
    .required()
    .label(intl.formatMessage({ id: 'Token' })),
  email: Yup.string()
    .email()
    .required()
    .label(intl.formatMessage({ id: 'Email' })),
  password: Yup.string()
    .required()
    .label(intl.formatMessage({ id: 'Password' })),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'Password does not match' }))
    .label(intl.formatMessage({ id: 'Repeat Password' })),
})
