/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { KTIcon } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'

const SidebarUser: FC = () => {
  const { currentUser } = useAuth()

  return (
    <div className="app-sidebar-user px-3" id="kt_app_sidebar_user">
      {/*begin::User*/}
      <div className="aside-user d-flex align-items-sm-center justify-content-center my-5 h-60px">
        {/*begin::Symbol*/}
        <div className="symbol symbol-50px">
          <div className="symbol-label fs-2 fw-semibold bg-white text-primary">
            {currentUser?.name[0]}
          </div>
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          {/*begin::Section*/}
          <div className="d-flex">
            {/*begin::Info*/}
            <div className="flex-grow-1 me-2">
              {/*begin::Username*/}
              <div className="text-white fs-6 fw-bold text-truncate mw-125px">
                {currentUser?.name}
              </div>
              {/*end::Username*/}

              {/*begin::Description*/}
              <div className="text-gray-600 fw-bold d-block fs-8 mb-1 text-capitalize">
                {currentUser?.role}
              </div>
              {/*end::Description*/}

              {/*begin::Label*/}
              <div className="d-flex align-items-center text-success fs-9 text-truncate mw-125px">
                {currentUser?.working_unit_name}
              </div>
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            <div className="me-n2">
              {/*begin::Action*/}
              <button
                type="button"
                className="btn btn-icon btn-sm btn-active-color-primary mt-n2"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-overflow="false"
              >
                <KTIcon iconName="setting-2" className="text-muted fs-1" />
              </button>

              <HeaderUserMenu />
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}
    </div>
  )
}

export { SidebarUser }
