/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }

    const body = document.body
    if (body) {
      body.classList.add(
        'app_blank',
        'bgi-size-cover',
        'bgi-attachment-fixed',
        'bgi-position-center',
        'bgi-no-repeat'
      )
    }

    return () => {
      if (root) {
        root.style.height = 'auto'
      }

      if (body) {
        body.classList.remove(
          'app_blank',
          'bgi-size-cover',
          'bgi-attachment-fixed',
          'bgi-position-center',
          'bgi-no-repeat'
        )
      }
    }
  }, [])

  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root">
      <style>{`
        body {
          background-image: url(${toAbsoluteUrl('/images/bg4.png')});
        }
        [data-bs-theme="dark"] body {
          background-image: url(${toAbsoluteUrl('/images/bg4.png')});
        }
      `}</style>

      {/* begin::Authentication - Sign-in */}
      <div className="d-flex flex-column flex-column-fluid flex-lg-row">
        {/* begin::Aside */}
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          {/* begin::Aside */}
          <div className="d-flex flex-center flex-lg-start flex-column">
            {/* begin::Logo */}
            <h1 className="text-white text-logo">CRIMS</h1>
            {/* end::Logo */}
            {/* begin::Title */}
            <h2 className="text-white fw-lighter fs-3 m-0">
              CBRN Rapid Information and Management System
            </h2>
            {/* end::Title */}
          </div>
          {/* end::Aside */}
        </div>
        {/* end::Aside */}
        {/* begin::Body */}
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
          {/* begin::Card */}
          <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
            {/* begin::Wrapper */}
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
              <Outlet />
            </div>
            {/* end::Wrapper */}

            <div className="d-flex justify-content-center px-lg-10">
              &copy; {new Date().getFullYear().toString()} All Rights Reserved.
            </div>
          </div>
          {/* end::Card */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Authentication - Sign-in */}
    </div>
  )
}

export { AuthLayout }
