import { useFormik } from 'formik'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { resetPasswordSchema } from './_validations'
import { ResetPasswordFormModel } from './_models'
import { resetPassword } from '../core/_requests'
import { KTIcon } from '../../../../_metronic/helpers'

export const ResetPassword = () => {
  const { token } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const email = params.get('email')

  // Initial
  const initialForm = useMemo<ResetPasswordFormModel>(
    () => ({
      token: token ?? '',
      email: email ?? '',
      password: '',
      password_confirmation: '',
    }),
    [email, token]
  )

  // State
  const [message, setMessage] = useState<string>('')

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { data } = await resetPassword(values)
        setMessage(data.data)

        setStatus('')

        setTimeout(() => {
          navigate('/auth/login')
        }, 3000)
      } catch (error: any) {
        console.error(ResetPassword.name, '-- onSubmit ::', error)
        setSubmitting(false)
        setMessage('')

        if (typeof error === 'object' && Object.keys(error).includes('response')) {
          setStatus(error.response.data.message)
        } else {
          setStatus(intl.formatMessage({ id: 'Invalid email or password' }))
        }
      }
    },
  })

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_setup_new_password_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: 'Setup New Password' })}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: 'Have you already reset the password' })} ?&nbsp;
          <Link to="/auth/login" className="link-primary fw-bold">
            {intl.formatMessage({ id: 'Log In' })}
          </Link>
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {formik.status ? (
        <div className="alert alert-danger d-flex align-items-center p-5">
          <KTIcon iconName="information" className="fs-2hx text-danger me-4" />
          <div className="d-flex flex-column">
            <h6 className="mb-0 text-danger">{formik.status}</h6>
          </div>
        </div>
      ) : null}

      {message && (
        <div className="alert alert-info d-flex align-items-center p-5">
          <KTIcon iconName="notification" className="fs-2hx text-info me-4" />
          <div className="d-flex flex-column">
            <h6 className="mb-0 text-info">{message}</h6>
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <input
          type="password"
          placeholder={intl.formatMessage({ id: 'Password' })}
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.password && formik.errors.password },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <input
          type="password"
          placeholder={intl.formatMessage({ id: 'Repeat Password' })}
          autoComplete="off"
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid':
                formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid':
                formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && (
            <span className="indicator-label">{intl.formatMessage({ id: 'Submit' })}</span>
          )}
          {formik.isSubmitting && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'Checking' })}...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
