import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { forgotPassword } from '../core/_requests'
import { ForgotPasswordFormModel } from './_models'
import { forgotPasswordSchema } from './_validations'
import { KTIcon } from '../../../../_metronic/helpers'

export function ForgotPassword() {
  const intl = useIntl()

  // Initial
  const initialForm = useMemo<ForgotPasswordFormModel>(
    () => ({
      email: '',
    }),
    []
  )

  // State
  const [message, setMessage] = useState<string>('')

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { data } = await forgotPassword(values.email)
        setMessage(data.data)

        setStatus('')
      } catch (error: any) {
        console.error(ForgotPassword.name, '-- onSubmit ::', error)
        setSubmitting(false)
        setMessage('')

        if (typeof error === 'object' && Object.keys(error).includes('response')) {
          setStatus(error.response.data.message)
        } else {
          setStatus(intl.formatMessage({ id: 'Invalid email or password' }))
        }
      }
    },
  })

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: 'Forgot Password' })} ?
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: 'Enter your email to reset your password.' })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {formik.status ? (
        <div className="alert alert-danger d-flex align-items-center p-5">
          <KTIcon iconName="information" className="fs-2hx text-danger me-4" />
          <div className="d-flex flex-column">
            <h6 className="mb-0 text-danger">{formik.status}</h6>
          </div>
        </div>
      ) : null}

      {message && (
        <div className="alert alert-info d-flex align-items-center p-5">
          <KTIcon iconName="notification" className="fs-2hx text-info me-4" />
          <div className="d-flex flex-column">
            <h6 className="mb-0 text-info">{message}</h6>
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <input
          type="email"
          placeholder={intl.formatMessage({ id: 'Email' })}
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && (
            <span className="indicator-label">{intl.formatMessage({ id: 'Submit' })}</span>
          )}
          {formik.isSubmitting && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'Checking' })}...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
