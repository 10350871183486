/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const { currentUser } = useAuth()

  return (
    <>
      {currentUser &&
        currentUser.menus.map((menu, index) => (
          <SidebarMenuItem key={index} to={menu.url} icon={menu.icon} title={menu.name} />
        ))}
    </>
  )
}

export { SidebarMenuMain }
