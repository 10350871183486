import axios from 'axios'
import { DefaultResponseModel, VerifyTokenResponseModel } from '../../../models/responses'
import {
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  RESET_PASSWORD_URL,
  VERIFY_TOKEN_URL,
} from './_constants'
import { ResetPasswordFormModel } from '../components/_models'

export const login = (email: string, password: string) => {
  return axios.post<DefaultResponseModel>(LOGIN_URL, { email, password })
}

export const verifyToken = (token: string) => {
  return axios.get<VerifyTokenResponseModel>(VERIFY_TOKEN_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const forgotPassword = (email: string) => {
  return axios.post<DefaultResponseModel>(FORGOT_PASSWORD_URL, { email })
}

export const resetPassword = (data: ResetPasswordFormModel) => {
  return axios.put<DefaultResponseModel>(RESET_PASSWORD_URL, data)
}

export const logout = () => {
  return axios.post<DefaultResponseModel>(LOGOUT_URL, {})
}
