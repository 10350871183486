export interface PermissionModel {
  access_control_id: string
  menu_id: string
  can_read: number
  can_create: number
  can_update: number
  can_delete: number
  can_approve: number
}

export enum Permission {
  READ = 'can_read',
  CREATE = 'can_create',
  UPDATE = 'can_update',
  DELETE = 'can_delete',
  APPROVE = 'can_approve',
}
