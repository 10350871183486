import { MenuTypeModel } from './MenuTypeModel'
import { PermissionModel } from './PermissionModel'

export interface MenuModel {
  id: string
  name: string
  alias: string
  url: string
  icon: string
  sequence: number
  type: MenuTypeModel
  permissions: PermissionModel
}

export enum Menu {
  DASHBOARD = 'dashboard',
  REPORT = 'reports',
  WORKING_UNIT = 'working-units',
  ACCESS_CONTROL = 'access-controls',
  USER = 'users',
}
