/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../../app/modules/auth'

const HeaderUserMenu: FC = () => {
  const { logout } = useAuth()
  const intl = useIntl()

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
      data-popper-placement="bottom-start"
    >
      <div className="menu-item px-5">
        <Link to={'/profile'} className="menu-link px-5 text-gray-800 text-hover-primary">
          {intl.formatMessage({ id: 'My Profile' })}
        </Link>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5 text-gray-800 text-hover-primary">
          {intl.formatMessage({ id: 'Sign Out' })}
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
