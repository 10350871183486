const SidebarFooter = () => {
  return (
    <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
      <div className="text-white text-center fw-bold gegana">PASUKAN GEGANA</div>
      <div className="text-white text-center fw-bold kbr">SATUAN KBR</div>
    </div>
  )
}

export { SidebarFooter }
