import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { useAuth } from '../core/Auth'
import { LoginFormModel } from './_models'
import { loginSchema } from './_validations'
import { login, verifyToken } from '../core/_requests'
import { KTIcon } from '../../../../_metronic/helpers'

export function Login() {
  const { saveAuth, setCurrentUser } = useAuth()
  const intl = useIntl()

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)

  // Initial
  const initialForm = useMemo<LoginFormModel>(
    () => ({
      email: '',
      password: '',
    }),
    []
  )

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth({ token: auth.data })

        const { data: verify } = await verifyToken(auth.data)
        setCurrentUser(verify.data)

        setStatus('')
      } catch (error: any) {
        console.error(Login.name, '-- onSubmit ::', error)
        saveAuth(undefined)
        setSubmitting(false)

        if (typeof error === 'object' && Object.keys(error).includes('response')) {
          setStatus(error.response.data.message)
        } else {
          setStatus(intl.formatMessage({ id: 'Invalid email or password' }))
        }
      }
    },
  })

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{intl.formatMessage({ id: 'Log In' })}</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: 'Please log in' })}
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className="alert alert-danger d-flex align-items-center p-5">
          <KTIcon iconName="information" className="fs-2hx text-danger me-4" />
          <div className="d-flex flex-column">
            <h6 className="mb-0 text-danger">{formik.status}</h6>
          </div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <input
          placeholder={intl.formatMessage({ id: 'Email' })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <div className="input-group">
          <input
            placeholder={intl.formatMessage({ id: 'Password' })}
            type={!isShowPassword ? 'password' : 'text'}
            autoComplete="off"
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <button
            onClick={() => setIsShowPassword((prev) => !prev)}
            className="btn btn-outline"
            type="button"
          >
            {isShowPassword ? (
              <KTIcon iconName="eye-slash" className="fs-2" />
            ) : (
              <KTIcon iconName="eye" className="fs-2" />
            )}
          </button>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        {/* begin::Link */}
        <Link to="/auth/forgot-password" className="link-primary">
          {intl.formatMessage({ id: 'Forgot Password' })} ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && (
            <span className="indicator-label">{intl.formatMessage({ id: 'Log In' })}</span>
          )}
          {formik.isSubmitting && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'Checking' })}...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
